import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import TwoColVert from "src/components/twoColVert"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"

const twoData = import("./data/data.json");

export default function prdbest() {

  let css;
       if(isChrome()){
         css = `
         `;
       }

  return(
    <HomepageLayout>
    <Helmet>
    <style type="text/css">
     {css}
         </style>
    <title>FabTab | Beautiful Backgrounds and the Latest Fashion News</title></Helmet>
    <section>
      <TwoColVert data={twoData}>
      <CtaDisclosureSearch></CtaDisclosureSearch>
    </TwoColVert>
      </section>
    </HomepageLayout>
  )
}
