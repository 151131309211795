import React from "react"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/twocolvert.module.scss"
import Data from "./data/twocolvert.json"
import FooterLinks from "src/components/footerlinksSingle/footer.js"


class twocolvert extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      pageData: props.data,
      data: Data,
      language: props.language
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();

      if(isChrome()){
        const browserData = Object.assign(this.state.data, this.state.data.chrome);
        this.setState({data: browserData});
      }
      if(isFirefox()){
        const browserData = Object.assign(this.state.data, this.state.data.firefox);
        this.setState({data: browserData});
      }
      this.setState({ language: this.props.language});
      if (typeof window != "undefined") {
        window.mapParams();
      }
    });
  }

  render() {
    return (
      <>
      <div id = {Styles.twocolvert} style={{ display: this.state.display }}>
     <main>
     <div id = {Styles.twocolleft}>
     <img src={this.state.data.promoimg} alt="EasyShare Logo" />
      </div>
      <div id = {Styles.twocolright}>
     <img src={this.state.data.mainLogo} alt="EasyShare Logo" />
       <h1 className = "hc_param">{this.state.data.headline}</h1>
       <ul className = {Styles.features}>
         <li>{this.state.data.feature2}</li>
         <li>{this.state.data.feature3}</li>
        </ul>


       <button className = {`${Styles.download} cl1`} data-cy="cta" onClick = {() => window.triggerInstall()}>{this.state.data.button}</button>
       {this.props.children}
       </div> 
       </main>
      <FooterLinks language={this.state.language}></FooterLinks>
      </div>
      </>
    )
  }
}
export default twocolvert
